import React from "react";
import "./Projects.css";
import {
  Grid,
  Typography,
  Avatar,
  makeStyles,
  Divider,
  Button,
} from "@material-ui/core/";
import Tweeter from "./Images/Projects/TweeterImg.png";
import TF1 from "./Images/Projects/TradefinderImg.jpeg";
import Resin from "./Images/Projects/ResinImg.png";
import Scheduler from "./Images/Projects/SchedulerImg.png";
import GS from "./Images/Projects/GameSetaImg.png"
import JS from "./Images/Jas-animation.png";
import { FaGithub, FaDribbble } from "react-icons/fa";
import { useIntersectionObserver } from "./useIntersectionObserver";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // flexWrap: 'wrap',
    justifyContent: "space-around",
    // overflow: 'hidden',
    marginTop: "25px",
  },

  body: {
    fontSize: "0.75rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.87rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },

  gridList: {
    width: "70%",
    height: 400,
  },

  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(22),
      height: theme.spacing(22),
    },
  },

  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },

  headers: {
    fontSize: "1.2rem",
    fontWeight: "bolder",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
}));

function Project() {
  const classes = useStyles();
  const elementRef = React.useRef(null);
  const [inView] = useIntersectionObserver(elementRef, {
    threshold: 0.35, rootMargin: "0px 0px -45px 0px"
  });

  return (
    <>
      <section id="projects" className={"fade-in " + (inView ? 'appear' : '')}>
        <Typography
          className={classes.headers}
          id="about-title"
          align="center"
          variant="h4"
          justify="center"
          gutterBottom
        >
          Projects
        </Typography>

        <Divider variant="middle" />

        <Grid
          container
          className={classes.root}
          direction="row"
          justify="center"
          alignItems="center"
          ref={elementRef}
        >
          <Grid item>
            <Avatar
              className={classes.avatar}
              id="avatar"
              style={{ border: "5px solid #856776" }}
              alt="Jas"
              src={JS}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="project-text">
            <Typography className={classes.body} align="center" gutterBottom>
              Below are just a few of the projects that I have worked on during
              my time at Lighthouse Labs. It has been fascinating learning new
              things whilst progressing through my course and I will be adding
              more projects to my portfolio in the near future.
            </Typography>
          </div>
        </Grid>
      </section>

      <section className="gameseta">
        <Typography
          className={classes.headers}
          id="project-titles"
          align="center"
          variant="h3"
          justify="center"
          gutterBottom
        >
          GameSeta
        </Typography>

        <Divider variant="middle" />

        <Typography
          className="stacks"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          Express, React and MongoDB
        </Typography>

        {/* <video className='videoTag' autoPlay loop muted>
          <source src={sample} type='video/mp4' />
        </video> */}

        <img alt="gameseta" src={GS} className="project-images" />

        <Typography
          className="project-desc"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          I volunteered for a start-up company and built a platform which allows High School Esports teams to monitor their performance against other competing schools. I wanted to learn something new, so I opted to use MongoDB to store the team, user and game information. 
        </Typography>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#192457", color: "#e4e4e4" }}
            className={classes.margin}
            endIcon={<FaDribbble />}
            href="https://dribbble.com/shots/14694768-Gaming-Performance-Monitoring"
            target="_blank"
          >
            View GameSeta on Dribbble
          </Button>
        </Grid>
      </section>

      <section className="tradefinder">
        <Typography
          className={classes.headers}
          id="project-titles"
          align="center"
          variant="h3"
          justify="center"
          gutterBottom
        >
          Tradefinder
        </Typography>

        <Divider variant="middle" />

        <Typography
          className="stacks"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          PostgreSQL, React and Ruby on Rails
        </Typography>

        <img alt="tradefinder" src={TF1} className="project-images" />

        <Typography
          className="project-desc"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          A collaborative project developed in the final two weeks at Lighthouse
          Labs. The inspiration behind this app all came down to my personal
          experience of trying to locate a reputable tradesperson. This app
          allows users to find and review tradespersons in their local area. I
          wanted to include a mailing service where users can contact various
          companies and also a live chat feature where users can speak with an
          expert, this gave me a great opportunity to research and learn about
          the Mail Gun mailing service and the Twilio live chat feature.
        </Typography>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#194457", color: "#e4e4e4" }}
            className={classes.margin}
            endIcon={<FaGithub />}
            href="https://github.com/J-sabharwal/Tradefinders"
            target="_blank"
          >
            View Tradefinder on Github
          </Button>
        </Grid>
      </section>

      <section className="scheduler">
        <Typography
          className={classes.headers}
          id="project-titles"
          align="center"
          variant="h3"
          justify="center"
          gutterBottom
        >
          Scheduler
        </Typography>

        <Divider variant="middle" />

        <Typography
          className="stacks"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          React, Storybook, Cypress and Jest
        </Typography>

        <img alt="scheduler" src={Scheduler} className="project-images" />

        <Typography
          className="project-desc"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          This single-page React app allows users to schedule interviews with a
          specific interviewer. This project was great to work on as I was
          introduced to many new technologies. I used Storybook to create user
          interface components, these components were then used within the app.
          Unit and Integration testing was performed using the Jest and Cypress
          frameworks.
        </Typography>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#50253d", color: "#e4e4e4" }}
            className={classes.margin}
            endIcon={<FaGithub />}
            href="https://github.com/J-sabharwal/scheduler"
            target="_blank"
          >
            View Scheduler on Github
          </Button>
        </Grid>
      </section>

      <section className="resin">
        <Typography
          className={classes.headers}
          id="project-titles"
          align="center"
          variant="h3"
          justify="center"
          gutterBottom
        >
          ResIn
        </Typography>

        <Divider variant="middle" />

        <Typography
          className="stacks"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          JQuery, PostgreSQL and Express
        </Typography>

        <img alt="resin" src={Resin} className="project-images" />

        <Typography
          className="project-desc"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          This project was in collaboration with a member of my cohort, we
          selected this particular project as we felt it would be a great tool
          for students like ourselves that wish to learn and have a platform for
          all their resources in one place. Users can view and save useful
          resources to their home page, provide feedback and categorize
          individual resources. We used a PostgreSQL database to store our
          resources and JQuery and Express to develop the app.
        </Typography>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#341d44", color: "#e4e4e4" }}
            className={classes.margin}
            endIcon={<FaGithub />}
            href="https://github.com/J-sabharwal/ResIn"
            target="_blank"
          >
            View ResIn on Github
          </Button>
        </Grid>
      </section>

      <section className="tweeter">
        <Typography
          className={classes.headers}
          id="project-titles"
          align="center"
          variant="h3"
          justify="center"
          gutterBottom
        >
          Tweeter
        </Typography>

        <Divider variant="middle" />

        <Typography
          className="stacks"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          JQuery, AJAX and Express
        </Typography>

        <img alt="tweeter" src={Tweeter} className="project-images" />

        <Typography
          className="project-desc"
          align="center"
          variant="body1"
          justify="center"
          gutterBottom
        >
          One of the earlier projects I completed was a Twitter clone page
          called Tweeter. The idea around this project was to become familiar
          with JQuery and AJAX, by creating a simple version of the app that
          allows users to post tweets.
        </Typography>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#1a5a3c", color: "#e4e4e4" }}
            className={classes.margin}
            endIcon={<FaGithub />}
            href="https://github.com/J-sabharwal/tweeter"
            target="_blank"
          >
            View Tweeter on Github
          </Button>
        </Grid>
      </section>
    </>
  );
}

export default Project;
