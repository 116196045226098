import React from "react";
import "./App.css";
import MenuIcon from "@material-ui/icons/Menu";
import {
  IconButton,
  Drawer,
  makeStyles,
  useTheme,
  Toolbar,
  AppBar,
  useScrollTrigger,
  Slide,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  grow: {
    flexGrow: 1,
    zIndex: 1000,
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      color: "#e2e2e2",
      textDecoration: "none",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  menuButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 200,
    backgroundColor: "#856776",
    display: "flex",
    "flex-direction": "column",
    "justify-content": "space-around",
    "align-items": "center",
    top: "8.7vh",
    [theme.breakpoints.up("sm")]: {
      top: "6.3vh", 
    },
    [theme.breakpoints.up("md")]: {
      top: "8vh", 
    },
    height: "60vh",
  },
}));

function App(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "menu-mobile";
  const renderMobileMenu = (
    <Drawer
      className="nav-links"
      id={mobileMenuId}
      anchorel={mobileMoreAnchorEl}
      anchororigin={{ vertical: "top", horizontal: "right" }}
      transformorigin={{ vertical: "top", horizontal: "right" }}
      anchor={theme.direction === "rtl" ? "right" : "left"}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      classes={{ paper: classes.drawerPaper }}
      keepMounted
    >
      <li>
        <a href="#top" onClick={handleMobileMenuClose}>
          Home
        </a>
      </li>
      <li>
        <a href="#intro" onClick={handleMobileMenuClose}>
          About
        </a>
      </li>
      <li>
        <a href="#project" onClick={handleMobileMenuClose}>
          Projects
        </a>
      </li>
      <li>
        <a href="mailto:jas@jaspinder.net" target="_blank" rel="noopener noreferrer" onClick={handleMobileMenuClose}>
          Contact
        </a>
      </li>
    </Drawer>
  );

  return (
    <div className={classes.grow}>
      <Slide appear={false} direction="down" in={!trigger}>
      <AppBar id="navbar" position="fixed" style={{ backgroundColor: "#5d4954" }}>
        <Toolbar>
          <div className={classes.sectionMobile}>
            <IconButton
              edge="start"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              style={{ color: "#e2e2e2" }}
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </div>
          {renderMobileMenu}

          <div className={classes.sectionDesktop}>
            <IconButton className="nav-links">
              <a href="#top">Home</a>
            </IconButton>
            <IconButton className="nav-links">
              <a href="#intro">About</a>
            </IconButton>
            <IconButton className="nav-links">
              <a href="#project">Projects</a>
            </IconButton>
            <IconButton className="nav-links">
              <a href="mailto:jas@jaspinder.net" target="_blank" rel="noopener noreferrer">Contact</a>
            </IconButton>
          </div>
          <div className={classes.grow} />
          <div className="App-logo">
            <h3>Jas Sabharwal</h3>
          </div>
        </Toolbar>
      </AppBar>
      </Slide>
    </div>
  );
}

export default App;
