import React from "react";
import "./Footer.css";
import { Container, Grid } from "@material-ui/core/";
import { FaGithubSquare, FaLinkedin, FaDribbbleSquare } from "react-icons/fa";

function Footer() {
  return (
    <div>
      <Container maxWidth="xl" className="footer">
        <Grid container item xs={12} className="footer-content">
          <Grid item className="footer-about" xs={4}>
            <h2 className="logo-text">Jas Sabharwal</h2>
            <p>
              Junior Full-stack Web Developer. Lighthouse Labs Alumni. I am
              currently available for work.
            </p>
            <div className="contact"></div>
            <div className="socials">
              <a
                href="https://www.linkedin.com/in/jaspinder-sabharwal-95a33a177"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://github.com/J-sabharwal"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaGithubSquare />
              </a>
              <a
                href="https://dribbble.com/Sabharwal"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaDribbbleSquare />
              </a>
            </div>
          </Grid>
          <Grid item xs={4} className="footer-links">
            <h2>Quick Links</h2>

            <ul>
              <a href="#top">
                <li>Home</li>
              </a>
              <a href="#intro">
                <li>About</li>
              </a>
              <a href="#project">
                <li>Projects</li>
              </a>
              <a href="mailto:jas@jaspinder.net" target="_blank" rel="noopener noreferrer">
                <li>Contact Me</li>
              </a>
            </ul>
          </Grid>
        </Grid>
      </Container>

      <Grid item className="footer-bottom">
        &copy; jaspinder.net 2020 | Designed by Jas Sabharwal
      </Grid>
    </div>
  );
}

export default Footer;


