import React from "react";
import "./Home.css";
import {
  Typography,
  Avatar,
  makeStyles,
  Divider,
} from "@material-ui/core/";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Jas from "./Images/Jas.png";
import City from "./Images/City.png";
import Project from "./Projects";
import { useIntersectionObserver } from "./useIntersectionObserver";
import SmoothImage from "react-smooth-image";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  },

  font: {
    fontSize: "0.70rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.45rem",
    },
  },

  subFont: {
    fontSize: "0.55rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.80rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },

  body: {
    fontSize: "0.75rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.87rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },

  headers: {
    fontSize: "1.2rem",
    fontWeight: "bolder",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
}));

function Home() {
  const classes = useStyles();
  const elementRef = React.useRef(null);
  const [inView] = useIntersectionObserver(elementRef, {
    threshold: 0.2, rootMargin: "0px 0px -20px 0px"
  });
    
  return (
    <>
      <div id="top"/>
      <div>
        <SmoothImage 
          src={City} 
          alt="Junior Developer" 
          id="homepage-image" 
          transitionTime={1} 
          containerStyles={{
            paddingBottom: "70%", 
            marginTop:"7vh"
          }}/>
        <div className="title">
          <p className={classes.font} align="center">
            Hello! I'm Jas
          </p>
          <Typography className={classes.subFont} align="center">
            A Ontario based Full Stack Developer.
          </Typography>
        </div>
        <a
          href="https://www.freepik.com/free-photos-vectors/background"
          style={{ textDecoration: "none", fontSize: "8px", color: "#e8e8e8" }}
        >
          Background vector created by rawpixel.com - www.freepik.com
        </a>
      </div>
      <div id="intro"></div>
      <section id="about" className={"fade-in " + (inView ? 'appear' : '')}>
       
        <Typography
          className={classes.headers}
          id="about-title"
          align="center"
          variant="h4"
          gutterBottom
        >
          About
        </Typography>
       
        <Divider variant="middle" />
        
          <div className="grid-container">
            <div className={"avatar slide-in from-left " + (inView ? 'appear' : '')}>
              <Avatar
                className={classes.avatar}
                id="profile-image"
                style={{ border: "5px solid #856776" }}
                alt="Jas"
                src={Jas}
                ref={elementRef}
              />
            </div>
          
            <div className={"about-me slide-in from-right " + (inView ? 'appear' : '')}>
              <Typography
                className={classes.body}
                align="left"
                justify="start"
                paragraph={true}
                gutterBottom
              >
                I always had an interest in technology and would jump at the
                chance of helping someone with their computer issues. Even when
                I didn’t know how to fix the problem, I just wanted the
                opportunity to learn and attempt to find a solution.
              </Typography>
              <Typography
                className={classes.body}
                align="left"
                justify="start"
                paragraph={true}
                gutterBottom
              >
                I decided to leave my job and pursue my passion for working in
                the tech industry and signed myself up for a Web Development
                Bootcamp. After three intense months of learning Javascript,
                HTML, CSS, React, and Ruby, I'm finally ready to take on this
                new life as a Junior Web Developer.
              </Typography>
              <Typography
                className={classes.body}
                align="left"
                justify="start"
                paragraph={true}
                gutterBottom
              >
                During my time at the Bootcamp, I have learnt a vast amount of
                technologies and want to continue to broaden my web development
                knowledge. I enjoy working on both Front-end and Back-end, as it
                challenges me to learn new skills.
              </Typography>
            </div>
          </div>
        <div id="project"></div>
      </section>
      <Project className="fade-in"/>
    </>
  );
}

export default Home;
